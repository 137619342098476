<template>
  <div class="flex flex-col flex-grow">
    <div class="container mx-auto flex justify-center flex-grow xl:pt-8">
      <div class="flex flex-col xl:flex-row items-center">
        <div class="flex flex-col order-last xl:order-first mr-0 xl:mr-16">
          <h3 class="font-EffraM text-3xl mb-3">Vérifiez votre e-mail!</h3>
          <div style="font-size: 20px" class="font-EffraR mb-10">
            Pour garantir la sécurité et la confiance de<br />
            la communauté, nous avons envoyé un<br />
            e-mail à
            <span class="text-dokBlue-ultra">{{ $route.query.email }}</span>
            pour vérification.<br />
            Vous ne le ferez qu'une seule fois.
          </div>
          <div class="text-lg font-EffraR" style="color: #767676">
            Vous n'avez pas reçu l’email?
            <button
              class="
                bg-transparent
                border-0
                text-dokBlue-ultra text-lg
                font-EffraM
                focus:outline-none
                cursor-pointer
              "
              @click="onSentActivation"
            >
              Ré-envoyer
            </button>
          </div>
        </div>
        <img
          src="/svg/verfication.svg"
          alt="Verfication Email Adress"
          class="order-first xl:order-last w-full xl:w-auto"
        />
      </div>
    </div>
    <!-- Footer -->
    <dok-footer
      :reverse-footer="true"
      :hidden-content="true"
      :downLoad-app="false"
    ></dok-footer>
  </div>
</template>

<script>
const dokFooter = () => import("./layouts/footer");

export default {
  components: { dokFooter },
  created() {
    if (
      !this.$route.query.email &&
      !this.validateEmail(this.$route.query.email)
    )
      return this.$router.push("/");
  },
  methods: {
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    async onSentActivation() {
      if (
        !this.$route.query.email &&
        !this.validateEmail(this.$route.query.email)
      )
        return this.$router.push("/");

      this.$vs.loading();
      await this.$store.dispatch("user/RESEND_EMAIL_ACTIVATION_CODE", {
        token: this.$route.query.token,
        onData: ({ ok, message }) => {
          this.$vs.loading.close();
          this.$vs.notify({
            time: 2500,
            color: !ok ? "danger" : "success",
            text: ok ? "Mail d’activation envoyé avec succès" : message,
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        },
      });
    },
  },
};
</script>

<style></style>
